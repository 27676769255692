<template>
  <div class="register-form">
    <form ref="registerForm" action="javascript:void(0)">
      <div class="register-step" v-if="step === 1">
        <TextField
          :type="'email'"
          v-model="email.value"
          :label="'Email*'"
          :isError="email.isError"
          :errorText="email.errorText"
          class="mb-8"
        />
        <div
          style="height: 0px"
          id="email-captcha"
          class="smart-captcha"
          :data-sitekey="ysc_key"
        ></div>
        <div
          class="register-step__button button_pink button"
          @click="restorePasswordAction"
        >
          Продолжить
        </div>
      </div>
      <div class="register-step" v-if="step === 2">
        <div v-if="!showHelp">
          <div class="register-step__title">Проверьте почту</div>
          <div class="register-step__description">
            Отправили письмо с кодом на {{ email.value }}.
            <span class="register-step__description_pink" @click="step--"
              >Изменить email?</span
            >
          </div>
          <div @click="showHelp = true" class="register-step__help">
            Письмо не пришло
          </div>
        </div>
        <div v-else>
          <div class="header-auth__help-caption">Что делать</div>
          <ol class="header-auth__help-list">
            <li class="header-auth__help-list-item">
              Вы могли ввести несуществующий email или ошибиться в букве.
              Пожалуйста, проверьте всё ещё раз.
            </li>
            <li class="header-auth__help-list-item">
              Проверьте папку «Спам» — возможно, письмо попало туда.
            </li>
          </ol>
          <div
            class="header-auth__back button button_empty-pink"
            @click="showHelp = false"
          >
            Назад
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 3">
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="password.value"
          :label="'Введите пароль*'"
          @blur="checkPassRules"
          :isError="password.isError"
          :errorText="password.errorText"
          :successState="password.success"
          class="mb-8 pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
          <template v-slot:alternate-error>
            <div class="pass-requirements">
              <div class="req__title">Требования к паролю</div>
              <ul class="req__list mt-2">
                <li class="req__point mb-2">Только латинские буквы</li>

                <li class="req__point">Минимум 8 символов</li>

                <li class="req__point">Минимум одна цифра</li>
              </ul>
            </div>
          </template>
        </TextField>
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="passwordRepeat.value"
          :isError="passwordRepeat.isError"
          :errorText="passwordRepeat.errorText"
          @input="checkPassRepeatRules"
          :label="'Повторите пароль*'"
          :successState="passwordRepeat.success"
          class="pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
        </TextField>

        <div class="buttons-container">
          <div
            class="register-step__button button_pink big-button button"
            @click="changePassword"
          >
            Продолжить
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 4">
        <div class="register-step__title">Пароль восстановлен</div>
        <div class="register-step__description">
          Теперь можете войти на сайт, используя введите пароль.
        </div>
        <router-link
          :to="{ name: 'Login', query: $route.query }"
          class="register-step__button button_pink button"
          >Войти</router-link
        >
      </div>
    </form>
    <RegisterFooter />
  </div>
</template>

<script>
import RegisterFooter from "./RegisterFooter.vue";
import TextField from "@/components/form-elements/TextField.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TextField,
    RegisterFooter,
  },
  name: "RestoreForm",
  data: () => ({
    showHelp: false,
    showPass: false,
    email: {
      value: "",
      isError: false,
      errorText: "",
    },
    personalData: false,
    step: 1,
    password: {
      value: "",
      isError: false,
      errorText: "",
      success: false,
    },
    passwordRepeat: {
      value: "",
      isError: false,
      errorText: "",
      success: false,
    },
    captcha: {
      email: {
        id: null,
      },
    },
  }),
  computed: {
    ...mapGetters(["Rules"]),
    ysc_key() {
      return process.env.VUE_APP_YSC_KEY
    },
    passData() {
      return {
        restore_id: this.$route.query.uuid,
        new_password: this.password.value,
        new_password_confirm: this.passwordRepeat.value,
      };
    },
  },
  methods: {
    ...mapActions(["restorePasswordStart", "restorePassword"]),
    async restorePasswordAction() {
      if (!this.Rules.isRequired(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Для регистрации нужно заполнить Email адрес";
        return;
      }
      if (!this.Rules.isCorrectEmail(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Некорректный Email";
        return;
      }
      if (window.smartCaptcha) {
        this.captcha.email.id = window.smartCaptcha.render("email-captcha", {
          sitekey: this.ysc_key,
          invisible: true, // Сделать капчу невидимой
        });
        window.smartCaptcha.subscribe(
          this.captcha.email.id,
          "success",
          this.emailCaptchaSuccess
        );

        window.smartCaptcha.execute(this.captcha.email.id);
      }
    },
    async emailCaptchaSuccess(token) {
      let checkEmail = this.restorePasswordStart({
        email: this.email.value,
        query: this.$route.query,
        captcha: token,
      });
      if (checkEmail) {
        this.step = 2;
      }
      window.smartCaptcha.destroy(this.captcha.email.id);
    },
    checkPassRules() {
      if (
        !this.Rules.onlyLatin(this.password.value) ||
        !this.Rules.minLength(this.password.value) ||
        !this.Rules.digitsReq(this.password.value) ||
        !this.Rules.isRequired(this.password.value)
      ) {
        this.password.isError = true;
        return false;
      }
      this.password.isError = false;
      this.password.success = true;
      return true;
    },
    checkPassRepeatRules() {
      if (!this.Rules.isRequired(this.passwordRepeat.value)) {
        this.passwordRepeat.errorText = "Введите пароль еще раз";
        this.passwordRepeat.isError = true;
        return false;
      }
      if (this.passwordRepeat.value !== this.password.value) {
        this.passwordRepeat.errorText = "Пароли не совпадают";
        this.passwordRepeat.isError = true;
        return false;
      }
      this.passwordRepeat.errorText = "";
      this.passwordRepeat.isError = false;
      this.passwordRepeat.success = true;
      return true;
    },
    async changePassword() {
      if (this.checkPassRules() && this.checkPassRepeatRules()) {
        await this.restorePassword(this.passData).then((res) => {
          if (res) {
            this.step = 4;
          }
        });
      }
    },
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.step = 3;
    }
  },
  watch: {
    step() {
      let hideLeft = this.step === 4;
      this.$emit("changeStep", "", hideLeft);
    },
    "email.value": function () {
      this.email.isError = false;
      this.email.errorText = "";
    },
    "password.value": function () {
      this.checkPassRules();
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  margin: 0 auto;
  width: 592px;

  @media screen and (max-width: 1220px) {
    width: 100%;
    margin: 40px 0 0;
  }

  @media screen and (max-width: 767px) {
    &:only-child {
      margin-top: 0;
    }
  }
}

.register-step {
  &__help {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
  &__button.back {
    width: 92px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
  &__button {
    margin-top: 32px;
    width: 162px;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .big-button {
    width: 229px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .buttons-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #3c4242;
    cursor: pointer;

    &_active {
      color: #d0006f;
    }
  }
}
.header-auth {
  &__help-caption {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #424753;
  }

  &__help-list {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #666666;
    padding-left: 15px;
    list-style-type: number;
  }

  &__help-list-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__back {
    margin-top: 16px;
    display: inline-flex;
  }
}
</style>
